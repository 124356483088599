<template>
    <el-form ref="form" class="relative" :model="form" :rules="rules">
        <LoadingComponent :isLoading="isLoading" />
        <div class="grid grid-cols-2 gap-4">
            <el-form-item class="w-full" prop="height">
                <label
                    >身高
                    <div class="relative">
                        <el-input v-model="form.height" class="w-full" :maxlength="3"></el-input>
                        <span class="absolute top-0 right-2 text-gray-400 font-light">公分 </span>
                    </div>
                </label>
            </el-form-item>
            <el-form-item class="w-full" prop="weight">
                <label
                    >體重
                    <div class="relative">
                        <el-input v-model="form.weight" class="w-full" :maxlength="3"></el-input>
                        <span class="absolute top-0 right-2 text-gray-400 font-light">公斤 </span>
                    </div>
                </label>
            </el-form-item>
        </div>
        <el-form-item prop="description">
            <label class="text-gray-400 font-light">文字介紹</label>
            <el-input
                v-model="form.description"
                type="textarea"
                rows="10"
                :maxlength="descriptionMaxLengthLimit"
                placeholder="寫下更多關於您的介紹，讓大家更認識您！（可以使用 # 強調內容喔）"></el-input>
            <div class="text-right">
                <span :class="form.description.length > descriptionMaxLengthLimit ? 'text-orange-600' : ''"
                    >{{ form.description.length }}/{{ descriptionMaxLengthLimit }}</span
                >
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import { mapState } from "vuex";
// 使用語系檔取得地區資料
import { areas } from "../../../../../../langs/tw.json";
// loading 動畫組件
import LoadingComponent from "../../../../../../components/Loading.vue";
/**
 * formCheckAgeRange: 判斷年紀不可小於 18 且不大於 99
 * formNumberRange: 判斷輸入值不可大於 999
 */
import { formCheckAgeRange, formNumberRange } from "../../../../../../service/formRules";
export default {
    components: {
        LoadingComponent,
    },
    props: {
        // 更新動畫
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("memberStore", ["memberData"]),
    },
    data() {
        return {
            form: {
                description: "",
                taboo: "",
            },
            rules: {
                height: [
                    // {
                    //     required: true,
                    //     message: "身高為必填",
                    //     trigger: "blur"
                    // },
                    { validator: this.formNumberRange, trigger: "blur" },
                    {
                        pattern: /^[0-9]*$/,
                        message: `身高必須為整數`,
                        trigger: "blur",
                    },
                ],
                weight: [
                    // {
                    //     required: true,
                    //     message: "體重為必填",
                    //     trigger: "blur"
                    // },
                    { validator: this.formNumberRange, trigger: "blur" },
                    {
                        pattern: /^[0-9]*$/,
                        message: `體重必須為整數`,
                        trigger: "blur",
                    },
                ],
                description: [
                    // {
                    //     required: true,
                    //     message: "文字介紹文為必填",
                    //     trigger: "blur"
                    // },
                    {
                        max: process.env.VUE_APP_INPUT_MAX_LIMIT,
                        message: `介紹文字最多${process.env.VUE_APP_INPUT_MAX_LIMIT}個字`,
                        trigger: "blur",
                    },
                ],
            },
            // 區域選擇,
            areaOptions: areas,
            // 文字介少最長字數限制
            descriptionMaxLengthLimit: process.env.VUE_APP_INPUT_MAX_LIMIT,
        };
    },
    methods: {
        /**
         * 判斷輸入值不可大於 999
         * @param { type String or Number(字串或數字) } value 輸入值
         */
        async formNumberRange(rule, value, callback) {
            // 判斷是身高還是體重的輸入匡錯誤
            const messageType = rule.field === "height" ? "身高" : "體重";
            const { success, message } = await formNumberRange(value);
            if (success) {
                return callback();
            }
            return callback(new Error(messageType + message));
        },
        // 判斷年齡不可大於 99且 不得小於 18
        async formCheckAgeRange(rule, value, callback) {
            const { success, message } = await formCheckAgeRange(value, true);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // about 表單資料回傳給父組件
                this.$emit("onAboutFormSubmit", this.form);
                // 表單驗證成功時回傳給父組件
                this.$emit("onAboutFormValidate", true);
            } catch (err) {
                // 表單驗證失敗時回傳給父組件
                this.$emit("onAboutFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 設定表單預設值
         * @param { type Object(物件) } memberData 會員資料
         */
        setDefault(memberData) {
            // 判斷是否有值
            if (memberData === undefined) {
                return;
            }
            this.form = {
                weight: memberData.weight,
                height: memberData.height,
                description: memberData.description ?? "",
            };
        },
        /**
         * 新增服務商守則文字到輸入匡
         * @param { type String(字串) } data 文字內容
         */
        addRuleBtn(data) {
            // 判斷服務商守則字數大於最大字數限制時不做任何事
            if (this.form.taboo.length >= this.descriptionMaxLengthLimit) {
                return;
            }
            // 目前的服務商守則資料
            let taboo = this.form.taboo;
            // 將點擊按鈕的文字加入服務商守則資料
            taboo += `${data}\n`;
            this.$set(this.form, "taboo", taboo);
        },
    },
    watch: {
        memberData(val) {
            this.setDefault(val);
        },
    },
    created() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
    activated() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
};
</script>
