<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3 bg-white rounded-lg shadow-lg">
            <div class="flex border-b border-gray-100 items-center pb-5 p-5">
                <Avatar :backgroundImg="defaultAvatar" />
                <div class="ml-2">
                    <h3 class="text-gray-400">{{ memberData.name }}</h3>
                    <p class="text-gray-300">帳號狀態:{{ memberData.status | showStatusText }}</p>
                </div>
            </div>
            <div class="border-b border-gray-100 p-5">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in menus['first']"
                        v-show="item.show"
                        :key="index"
                        class="cursor-pointer pl-5 py-3"
                        :class="item.value === showComponent ? 'bg-red-500 bg-opacity-20 rounded-lg' : ''"
                        @click="changeShowComponent(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
            <div class="p-5">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in menus['second']"
                        v-show="item.show"
                        :key="index"
                        class="cursor-pointer pl-5 py-3"
                        :class="item.value === showComponent ? 'bg-red-500 bg-opacity-20 rounded-lg' : ''"
                        @click="changeShowComponent(item.value)">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative" :class="{ 'bg-white p-5': inMenuFirst && showComponent !== 'Advanced' }">
            <LoadingComponent :isLoading="loading" />
            <keep-alive>
                <component
                    :is="showComponent"
                    :ref="showComponent"
                    :isUpdate="true"
                    :isProvider="false"
                    :profileFormData.sync="profileForm"
                    :profileFormValidate.sync="profileFormValidate"
                    :aboutFormValidate.sync="aboutFormValidate"
                    :deafaulAllSelectHour="true"
                    :isAdd="false"
                    :categoriesFormData.sync="categoriesForm"
                    :hoursFormData.sync="hoursForm"
                    :serviceFormValidate.sync="serviceFormValidate"
                    :onUploadBankPhotoSuccess.sync="salaryBankPhotoUplaodSuccess"
                    :salaryFormData.sync="salaryForm"
                    :salaryFormValidate.sync="salaryFormValidate"
                    @aboutFormDataCallback="aboutFormData"
                    @updateAvatar="changeAvatar"
                    @updateNotesList="getNotesList">
                    <template v-slot:provider-remarks>
                        <Remarks :datas="noteDatas" />
                    </template>
                </component>
            </keep-alive>
            <div v-if="inMenuFirst && showComponent !== 'Advanced'" class="flex justify-center mt-10">
                <!-- <button @click.prevent="$router.push({name:'provider_list'})"
                        class="black-btn md:text-sm text-xs max-w-[150px] flex-grow mr-3">取消</button> -->
                <btn v-permission="['update']" @onClick="onSubmit('form')"> 資料更新 </btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Avatar from "@/components/Avatar.vue";
import Profile from "../updateMember/components/Profile.vue";
import Advanced from "../updateMember/components/Advanced.vue";
import About from "../updateMember/components/about/index.vue";
import OrderList from "../../components/OrderList.vue";
import PaymentFlowList from "../../components/PaymentFlowList.vue";
import PaymentVoucherList from "../../components/PaymentVoucherList.vue";
import EvaluationList from "../../components/EvaluationList.vue";
import Remarks from "@/components/Remarks.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 導入 firebase 更新聊天室全部聊天對象資料方法
import firebaseChatMixin from "@/service/firebaseChatMixin";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
export default {
    name: "userUpdate",
    mixins: [firebaseChatMixin],
    components: {
        Profile,
        Advanced,
        About,
        OrderList,
        PaymentFlowList,
        EvaluationList,
        Avatar,
        Remarks,
        LoadingComponent,
        PaymentVoucherList,
        Btn,
    },
    filters: {
        showStatusText(val) {
            switch (val) {
                case 0:
                    return "使用中";
                case -1:
                    return "停權";
                case -2:
                    return "永久停權";
                default:
                    return val;
            }
        },
    },
    computed: {
        ...mapState("memberStore", ["memberData"]),
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
        // 此頁是否在 menu first 中
        inMenuFirst() {
            const index = this.menus.first.findIndex((i) => i.value === this.showComponent);
            return index !== -1;
        },
        menus() {
            return {
                first: [
                    { label: "個人資料", value: "Profile", show: this.checkPermission("Profile") },
                    { label: "關於我", value: "About", show: this.checkPermission("About") },
                    { label: "進階設定", value: "Advanced", show: this.checkPermission("Advanced") },
                ],
                second: [
                    { label: "訂單記錄", value: "OrderList", show: this.checkPermission("OrderList") },
                    { label: "金流記錄", value: "PaymentFlowList", show: this.checkPermission("PaymentFlowList") },
                    { label: "快閃折抵金紀錄", value: "PaymentVoucherList", show: this.checkPermission("PaymentVoucherList") },
                    { label: "評價記錄", value: "EvaluationList", show: this.checkPermission("EvaluationList") },
                ],
            };
        },
    },
    data() {
        return {
            showComponent: null,
            // 預設大頭照
            defaultAvatar: null,
            // 旋轉動畫
            loading: false,
            // 客服註記列表資料
            noteDatas: [],
            // profile 表單資料
            profileForm: {},
            // 分類表單資料
            categoriesForm: {},
            // 服務時間表單資料
            hoursForm: {},
            // 薪轉表單資料
            salaryForm: {},
            // 更新動畫
            isLoading: false,
            // 判斷 profile 表單是否驗證正確 預設值為 true
            profileFormValidate: true,
            // 判斷 about 表單是否驗證正確 預設值為 true
            aboutFormValidate: true,
            // 判斷 service 表單是否有通過驗證 預設值為 true
            serviceFormValidate: true,
            // 判斷 salary 表單驗證是否有通過驗證 預設為 true
            salaryFormValidate: true,
            // 判斷 存摺是否有上傳成功  預設為 true
            salaryBankPhotoUplaodSuccess: true,
            // 顯示左上角使用者資料
            userData: {
                name: null,
                role: null,
            },
        };
    },
    methods: {
        ...mapMutations("memberStore", ["setMemberData"]),
        // 更新關於我 (因為子組件 非同步因素 因此 等待完子組件 全部做完後 在 callback 執行更新)
        aboutFormData(val) {
            this.update({ ...val });
        },
        // 切換顯示組件
        changeShowComponent(val) {
            this.loading = true;
            this.showComponent = val;
            this.$router.push({
                name: "member_update",
                params: { id: this.$route.params.id, tab: val },
            });
            this.getData();
        },
        /**
         * 取得當前服務商資料
         */
        async getData() {
            try {
                // 取得服務商資料
                const { data } = await this.$api.GetUserDataApi(this.$route.params.id);
                this.loading = false;
                // 設定 服務商 資料
                this.setMemberData(data);
                this.setDefault(data);
                this.setBreadcrumb(data);
                return data;
            } catch (err) {
                console.log(err);
                this.loading = false;
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        },
        /**
         * 設定表單資料
         * @param { type Object(物件) } val 使用者資料
         */
        setDefault(val) {
            this.defaultAvatar = val.avatar;
            this.$refs[this.showComponent].setDefault(val);
        },
        /**
         * 設定 Breadcrumb
         * @param { type Object(物件) } val 使用者資料
         */
        setBreadcrumb(val) {
            // 用戶管理・會員管理・{會員暱稱}・{當前選單分頁}
            let tab = "";
            for (const i in this.menus) {
                const index = this.menus[i].findIndex((i) => i.value === this.showComponent);
                if (index !== -1) {
                    tab = this.menus[i][index].label;
                    break;
                }
            }
            this.$store.commit("breadcrumbStore/setCrumbList", [
                {
                    meta: {
                        text: "用戶管理",
                    },
                },
                {
                    name: "member_list",
                    meta: {
                        text: val.role === 0 ? "會員管理" : "服務商管理",
                    },
                    path: "#/user_list/member_list",
                },
                {
                    meta: {
                        text: val.name,
                    },
                },
                {
                    name: this.$route.name,
                    meta: {
                        text: tab,
                    },
                },
            ]);
        },
        /**
         * 取得客服註記
         */
        async getNotesList() {
            try {
                const { data } = await this.$api.GetNotesListApi(this.$route.params.id, 2000);
                this.noteDatas = data.data;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得客服註記失敗",
                });
            }
        },
        /**
         * 表單發送
         * @param { type Strin(字串) } formName 表單名稱
         */
        async onSubmit(formName) {
            try {
                // 觸發 子組件 onSubmit 方法
                await this.$refs[this.showComponent].onSubmit(formName);
                switch (this.showComponent) {
                    case "About":
                        if (!this.aboutFormValidate) {
                            return;
                        }
                        break;
                    case "Profile":
                        if (!this.profileFormValidate) {
                            return;
                        }
                        this.update({ ...this.profileForm });
                        break;
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增失敗",
                });
            }
        },
        /**
         * 更新會員資料
         *
         */
        async update(form) {
            this.loading = true;
            try {
                await this.$api.UpdateProfileApi(this.$route.params.id, form);
                this.loading = false;
                // // 重新取得使用者資料
                // const userData = await this.getData();
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                // await this.updateFirebaseChatRoomUserData(userData.banana_id, userData);
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
                // this.$router.push({ name: "provider_list" });
            } catch (err) {
                this.loading = false;
                this.getData();
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        /**
         * 更新服務時間
         * @param { type Strign(字串) } userId 始用者id
         * @param { type Array(物件) } form 服務時間
         */
        async updateBusinessHour(userId, form) {
            try {
                await this.$api.UpdateOrCreateBusinessHourApi(userId, form);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增服務時間失敗",
                });
            }
        },
        /**
         * 更新分類
         * @param { type Strign(字串) } userId 始用者id
         * @param { type Object(物件) } form 服務時間
         */
        async updateAllCategories(userId, form) {
            try {
                await this.$api.UpdateOrCreateAllCategories(userId, form);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: `新增分類失敗`,
                });
            }
        },
        /**
         * 更新頭貼
         * @param { type Strign(字串) } avatar 始用者頭貼url
         */
        changeAvatar(avatar) {
            this.defaultAvatar = avatar;
        },
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
    },
    watch: {
        "$route.params.id"(val) {
            // 判斷是否有帶入 params tab 參數
            if (this.$route.params.tab !== undefined) {
                this.showComponent = this.$route.params.tab;
            } else {
                this.showComponent = "Profile";
            }

            /**
             * 判斷值有改變 且 會員資料編輯頁處才觸發
             * 因為 keep-alive 機制會讓 watch 在別的頁面也會執行 所以需要判斷 route name
             */
            if (val && this.$route.name === "member_update") {
                this.getData();
                this.getNotesList();
            }
        },
    },
    async created() {
        this.getData();
        this.getNotesList();
        // 判斷是否有帶入 params tab 參數
        if (this.$route.params.tab !== undefined) {
            this.showComponent = this.$route.params.tab;
        } else {
            this.showComponent = "Profile";
        }
    },
};
</script>
