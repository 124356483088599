<template>
    <Dialog :showDialog="showDialogs" :customClass="'position'">
        <div class="pop-width md:py-10 md:px-3 px-3 py-5 bg-white rounded">
            <div class="close" @click="cancel">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">扣除快閃折抵金</div>
                <div class="des mb-3">扣除本用戶的快閃折抵金</div>
                <div class="input-item">
                    <div class="label">客訴單編號</div>
                    <el-input v-model="input.feedback_id" placeholder="請輸入客訴單編號" size="small"></el-input>
                </div>

                <!-- 依據活動新增 -->
                <div class="input-item">
                    <div class="label">折抵金編號</div>
                    <el-select
                        v-model="input.code"
                        style="width: 300px"
                        size="small"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="輸入折抵金編號"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option v-for="item in options" :key="item.code" :label="item.code" :value="item.code">
                            <div class="option">
                                {{ item.code }}
                                <span class="text-color">{{ showLabel(item) }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </div>
                <div v-if="errorMessage" class="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button :disabled="disable" class="orange-btn-800 md:text-sm text-xs w-[100px] h-[30px]" @click="confirm"> 扣除折抵金 </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
    name: "PopupRemoveVoucher",
    components: {
        Dialog,
    },
    props: {
        // 當前 user 是否為服務商
        value: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        showDialogs: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            disable: true,
            errorMessage: "",
            list: [],
            loading: false,
            options: [],
            input: {
                // 客訴單編號
                feedback_id: "",
                code: "",
            },
        };
    },
    methods: {
        cancel() {
            this.showDialogs = false;
        },
        confirm() {
            const { code, feedback_id } = this.input;
            let request = {};

            request = {
                feedback_id,
                code,
            };

            this.$emit("confirm", request);
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.options = this.list.filter((item) => {
                        return item.code.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.options = [...this.list];
            }
        },
        showLabel(item) {
            return `( 總金額 : ${item.amount} , 已使用 : ${item.used} , 剩餘 : ${item.amount - item.used} )`;
        },
    },
    watch: {
        input: {
            handler(val) {
                this.disable = true;
                const { code, feedback_id } = val;
                if (code && feedback_id) {
                    this.disable = false;
                }
            },
            deep: true,
        },
        value() {
            this.input = {
                feedback_id: "",
                code: "",
            };
        },
    },
    mounted() {
        this.$api.GetUserVoucherTickets(this.$route.params.id, { limit: 1000 }).then((res) => {
            this.list = [...res.data.data.filter((i) => i.status !== -2)];
            this.options = [...res.data.data.filter((i) => i.status !== -2)];
        });
    },
};
</script>

<style lang="scss">
.el-radio__input.is-checked + .el-radio__label {
    @apply text-gray-700;
}
.pop-width {
    max-width: 350px;
    margin: 0 162px 0 0;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
}

.input-item {
    width: 80%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
        width: 150px;
        color: #2c2c2c;
        font-size: 14px;
        margin-right: 10px;
        white-space: nowrap;
    }
}

.errorMessage {
    width: 80%;
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}
.option {
    color: rgb(68, 65, 65);
    font-size: 14px;
    font-weight: 700;
    .text-color {
        font-weight: 500;
        color: #8b8383;
    }
}

@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>
