<template>
    <div>
        <h5 class="md:text-2xl text-lg mb-5 mt-10">編輯照片</h5>
        <UploadImage ref="uploadImage" @onAboutFormValidate="validateCallBack" />
        <slot name="video-upload"></slot>
        <h5 class="md:text-2xl text-lg md:mb-10 mb-5 mt-10">進階敘述</h5>
        <Form ref="aboutForm" :isLoading="isLoading" @onAboutFormSubmit="callBack" @onAboutFormValidate="validateCallBack" />
    </div>
</template>
<script>
import { mapState } from "vuex";
// 檔案上傳
import UploadImage from "./UploadImage.vue";
import Form from "./Form.vue";
export default {
    components: {
        UploadImage,
        Form,
    },
    props: {
        // 更新動畫
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("memberStore", ["memberData"]),
    },
    methods: {
        onSubmit(form) {
            this.$refs.aboutForm.onSubmit(form);
            this.$refs.uploadImage.onSubmit();
        },
        /**
         * 表單驗證成功後回傳值
         * @param { type Object(物件) } form 表單資了
         */
        callBack(form) {
            // about 資料表單驗證成功時的回傳表單資料
            this.$emit("update:aboutFormData", form);
            // 因為 更新時的 非同步 延遲問題 因此改用 function 作法 等待做完後 才執行更新 about 表單資料
            this.$emit("aboutFormDataCallback", form);
        },
        /**
         * 表單驗證失敗返回
         * @param { type Boolean(布林值) } validate 表單驗證結果
         */
        validateCallBack(validate) {
            // about資料 表單驗證結果回傳
            this.$emit("update:aboutFormValidate", validate);
        },
        // 設定表單資料
        setDefault(val) {
            this.$refs.aboutForm.setDefault(val);
            this.$refs.uploadImage.setDefault(val);
        },
    },
    watch: {
        memberData(val) {
            this.setDefault(val);
        },
    },
};
</script>
