<template>
    <Dialog :showDialog="showDialogs" :customClass="'position'">
        <div class="pop-width md:py-10 md:px-3 px-3 py-5 bg-white rounded">
            <div class="close" @click="cancel">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">新增快閃折抵金</div>
                <div class="des mb-3">新增本用戶的快閃折抵金</div>
                <div class="input-item">
                    <div class="label">客訴單編號</div>
                    <el-input v-model="input.feedback_id" placeholder="請輸入客訴單編號" size="small"></el-input>
                </div>

                <!-- 新增方式 -->
                <div class="input-item">
                    <div class="label">新增方式</div>
                    <el-radio-group v-model="exchange">
                        <div class="mb-2">
                            <el-radio :label="1">依據活動新增</el-radio>
                        </div>
                        <div>
                            <el-radio :label="0">直接新增</el-radio>
                        </div>
                    </el-radio-group>
                </div>

                <!-- 直接新增 -->
                <div v-if="exchange == '0'" class="input-item">
                    <div class="label">新增金額</div>
                    <el-input v-model="input.amount" type="number" max="50000" size="small" placeholder="請輸入新增金額"></el-input>
                </div>
                <div v-if="exchange == '0'" class="input-item">
                    <div class="label">折抵金到期日</div>
                    <el-date-picker
                        v-model="input.expired_at"
                        value-format="yyyy-MM-dd"
                        type="date"
                        size="small"
                        placeholder="选择日期"></el-date-picker>
                </div>

                <!-- 依據活動新增 -->
                <div v-if="exchange == '1'" class="input-item">
                    <div class="label">活動代號</div>
                    <el-input v-model="input.campaign_code" size="small" placeholder="請輸入活動代號"></el-input>
                </div>

                <div v-if="errorMessage" class="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button :disabled="disable" class="orange-btn-800 md:text-sm text-xs w-[100px] h-[30px]" @click="confirm"> 新增折抵金 </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

const inputDefault = {
    // 直接新增
    expired_at: "",
    amount: "",
};

const inputCampaign = {
    // 依據活動新增
    campaign_code: "",
};

export default {
    name: "PopupAddVoucher",
    components: {
        Dialog,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Boolean,
            require: true,
        },
        currentBalance: {
            type: Number,
            require: true,
        },
    },
    computed: {
        showDialogs: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            disable: true,
            errorMessage: "",
            // 新增方式
            exchange: null,
            input: {
                // 客訴單編號
                feedback_id: "",
            },
        };
    },
    methods: {
        cancel() {
            this.showDialogs = false;
        },
        confirm() {
            const { expired_at, amount, campaign_code, feedback_id } = this.input;
            let request = {};
            if (this.exchange == "0") {
                request = {
                    feedback_id,
                    expired_at,
                    amount,
                    exchange: this.exchange,
                };
            } else {
                request = {
                    feedback_id,
                    campaign_code: parseInt(campaign_code),
                    exchange: this.exchange,
                };
            }
            this.showDialogs = false;
            this.$emit("confirm", request);
        },
    },
    watch: {
        input: {
            handler(val) {
                this.disable = true;
                if (this.exchange === 0) {
                    // 檢查直接新增輸入
                    const { expired_at, amount, feedback_id } = val;
                    if (amount > 50000) {
                        this.errorMessage = "單筆最多新增50,000";
                        return;
                    }
                    if (expired_at && amount && feedback_id) {
                        this.disable = false;
                    }
                } else {
                    const { campaign_code, feedback_id } = val;
                    if (campaign_code && feedback_id) {
                        this.disable = false;
                    }
                }
            },
            deep: true,
        },
        value() {
            this.exchange = null;
            this.input = {
                feedback_id: "",
            };
        },
        exchange(val) {
            if (val === "0") {
                this.input = { feedback_id: this.input.feedback_id, ...inputDefault };
            } else {
                this.input = { feedback_id: this.input.feedback_id, ...inputCampaign };
            }
        },
    },
};
</script>

<style lang="scss">
.el-radio__input.is-checked + .el-radio__label {
    @apply text-gray-700;
}
.pop-width {
    max-width: 350px;
    margin: 0 162px 0 0;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
}

.input-item {
    width: 80%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
        width: 150px;
        color: #2c2c2c;
        font-size: 14px;
        margin-right: 10px;
        white-space: nowrap;
    }
}

.errorMessage {
    width: 80%;
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}

@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>
