<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-3">
                    <label class="text-gray-400">
                        快閃折抵金編號
                        <el-input v-model="filterOpts.voucher_code" size="small" clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        交易日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        事件
                        <el-select v-model="filterOpts.type" size="small" class="w-full" clearable>
                            <el-option v-for="(type, index) in voucherTypeOptions" :key="index" :label="type.label" :value="type.value">
                                {{ type.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>

                <div class="col-span-2 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="flex area-pay mb-2">
            <div class="pay-balance flex-2 shadow-lg rounded-lg">快閃折抵金餘額 : $ {{ voucher | formatCurrency }}</div>
            <button
                v-permission="['update']"
                class="flex-1 orange-btn-800 md:text-sm text-xs mx-2 w-[73px] h-[43px] flex-1"
                @click="showPopUp('add')">
                新增快閃折抵金
            </button>
            <button v-permission="['update']" class="flex-1 black-btn-black w-[73px] h-[43px]" @click="showPopUp('remove')">扣除快閃折抵金</button>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>快閃折抵金編號</th>
                        <th>事件</th>
                        <th>金額</th>
                        <th>餘額</th>
                        <th>交易時間</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.voucher.code }}</td>
                        <td>
                            {{ item.type | voucherType }}
                            <div v-if="item.type === 'EARN' && item.details.campaign">（{{ item.details.campaign }}）</div>
                            <span v-if="item.details.order_id && ['BURN', 'REFUND'].includes(item.type)">
                                (
                                <span class="memo text-red-500">
                                    {{ item.details.order_id }}
                                </span>
                                )
                            </span>
                        </td>
                        <td>
                            <span>{{ checkType(item.type) ? `- ${item.amount}` : `+ ${item.amount}` }}</span>
                        </td>
                        <td>${{ item.balance | formatCurrency }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <PopupAddVoucher v-model="showDialogPopup" @confirm="addUserVoucher" />
        <PopupRemoveVoucher v-if="showDialogPopupDeduct" v-model="showDialogPopupDeduct" @confirm="removeUserVoucher" />
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 新增扣除餘額彈窗
import PopupAddVoucher from "./PopupAddVoucher.vue";
import PopupRemoveVoucher from "./PopupRemoveVoucher";

import orderConfig from "@/config/orderConfig";
// init search Filter options
const defaultFilterOpts = {
    voucher_code: "",
    type: "",
    created_at_start: "",
    created_at_end: "",
};
export default {
    name: "PaymentFlowList",
    components: {
        LoadingComponent,
        MyPagination,
        PopupAddVoucher,
        PopupRemoveVoucher,
    },
    props: {
        // 當前 user 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const request = { ...this.filterOpts };
            // 過濾空值
            if (!request.created_at_start) {
                delete request.created_at_start;
                delete request.created_at_end;
            }
            if (!request.voucher_code) {
                delete request.voucher_code;
            }
            if (!request.type) {
                delete request.type;
            }
            return {
                ...request,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 付款狀態 options
        voucherTypeOptions() {
            const config = orderConfig.voucherTypes;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in config) {
                result.push({
                    label: config[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "",
            voucher: 0,
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            showDialogPopup: false,
            showDialogPopupDeduct: false,
            popUpInfo: {
                title: "新增餘額",
                message: "新增本用戶的餘額",
                type: "add",
            },
        };
    },
    methods: {
        checkType(type) {
            if (type === "BURN" || type === "EXPIRE" || type === "DEDUCT") {
                return true;
            }
            return false;
        },
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
            this.getVoucher();
        },
        // 讀取 list api
        async getList() {
            console.log(this.filterData);
            this.loading = true;
            const { status, data } = await this.$api
                .GetUserVoucherList(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 取餘額
        getVoucher() {
            this.$api
                .GetUserDataApi(this.id)
                .then((res) => {
                    console.log(res.data.wallet.voucher);
                    this.voucher = res.data.wallet.voucher;
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得餘額失敗",
                    });
                });
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 到訂單細節頁,將此頁資訊帶入
        goEdit(id) {
            const userID = this.$route.params.id;
            const tab = this.isProvider ? "provider_update" : "member_update";
            const pre = "PaymentFlowList";
            // this.$router.push({
            //     name: "order_detail",
            //     params: { tab, pre, id, userID }
            // });
            window.open(`/#/${userID}/${pre}/${tab}/order_detail/${id}`);
        },
        goCustomer(id) {
            // this.$router.push({
            //     name: "customer-complaint-detail",
            //     params: {
            //         customerID: id
            //     }
            // });
            // const idx = parseInt(id);
            // this.$router.push({
            //     name: "customer-complaint-detail",
            //     customerID: idx
            // });
            window.open(`/#/customer_complaint/customer/${id}`);
        },
        // 新增扣除餘額
        addUserVoucher(val) {
            this.$api
                .CreateUserVoucher(this.id, val)
                .then((res) => {
                    console.log(res);
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getList();
                    this.getVoucher();
                })
                .catch((err) => {
                    console.log(err);
                    const msg = "無此客訴單號";
                    this.$message({
                        type: "error",
                        message: msg,
                    });
                });
        },
        // 新增扣除餘額
        removeUserVoucher(val) {
            this.$api
                .UpdateSingleVoucher(val.code, { feedback_id: val.feedback_id, status: -2 })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getList();
                    this.getVoucher();
                })
                .catch((err) => {
                    console.log(err);
                    const msg = "更新失敗";
                    // if (err.response.data.error?.message === "Insufficient balance") {
                    //     msg = "餘額不足";
                    // }
                    this.$message({
                        type: "error",
                        message: msg,
                    });
                })
                .finally(() => {
                    this.showDialogPopupDeduct = false;
                });
        },
        // 顯示 PopUp
        showPopUp(type) {
            if (type == "add") {
                this.showDialogPopup = true;
            } else {
                this.showDialogPopupDeduct = true;
            }
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_at_start = "";
                this.filterOpts.created_at_end = "";
                return;
            }
            this.filterOpts.created_at_start = val[0];
            this.filterOpts.created_at_end = val[1];
        },
    },
    activated() {
        if (this.id) {
            this.getList();
            this.getVoucher();
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.area-pay {
    display: flex;
    height: 43px;
    .pay-balance {
        flex: 2;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.memo {
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
}

.memo-not {
    font-size: 14px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
